import {
  faGlobe,
  faShieldCheck,
  faStreetView,
} from '@awesome.me/kit-b89bd90d95/icons/classic/regular';
import { AIDoctorsCardsData } from '@/data/static/ts/AIDoctor';
import { fantasticCardsData } from '@/data/static/ts/AIHealthAssistant';
import { SIGN_UP_URL } from '@/utils/config';
import { benefitCardsData } from '@/data/static/ts/SecondOpinion';
import { HomeServicesData } from '@/data/static/ts/HomeServices';

export const mediumCardsWithIconsSectionData = {
  home: {
    title: 'Benefits of Personal AI Health Assistant',
    text: 'We have all you need to better understand your health and make informed and accurate decisions about your well-being.',
    linkUrl: SIGN_UP_URL,
    buttonAction: undefined,
    buttonText: 'Try Docus for Free',
    cardsData: HomeServicesData,
  },
  supplements: {
    title: 'Trusted by thousands',
    text: 'Join the community of health-conscious individuals who trust Docus for their supplement needs.',
    linkUrl: '',
    buttonAction: undefined,
    buttonText: '',
    cardsData: [
      {
        id: 1,
        icon: faStreetView,
        title: 'Personalized Approach',
        text: 'Our AI Doctor personalizes the checkup process to tailor supplements specifically to your unique health needs.',
      },
      {
        id: 2,
        icon: faShieldCheck,
        title: ' Doctor-Trusted Ingredients',
        text: 'We use the highest quality ingredients trusted by healthcare professionals. All Docus supplements are GMP-certified.',
      },
      {
        id: 3,
        icon: faGlobe,
        title: 'Fully Online Experience',
        text: 'Enjoy a seamless online process, from taking the quiz to receiving your supplement kit at your doorstep.',
      },
    ],
  },
  'ai-doctor': {
    title: 'Benefits of Docus AI Doctor',
    text: '',
    linkUrl: SIGN_UP_URL,
    buttonAction: undefined,
    buttonText: 'Explore AI Doctor',
    cardsData: AIDoctorsCardsData,
  },
  'ai-health-assistant': {
    title: 'Why it’s Fantastic',
    text: '',
    linkUrl: SIGN_UP_URL,
    buttonAction: undefined,
    buttonText: 'Try Docus for Free',
    cardsData: fantasticCardsData,
  },
  'second-opinion': {
    title: 'Benefits for patients & caregivers',
    text: '',
    linkUrl: '/doctors',
    buttonAction: undefined,
    buttonText: 'Explore Top Doctors',
    cardsData: benefitCardsData,
  },
};

export type MediumCardsWithIconsDataByPageName =
  keyof typeof mediumCardsWithIconsSectionData;
